/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

// images for the page
import expertPic from "assets/images/expert.jpg";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="production_quantity_limits"
                    title="99% de graça"
                    description="Os FLUs são tokens de Ethereum e você precisa gastar ETH para enviar para outra conta, mas eles valem praticamente nada."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="explore"
                    title="Apreender a usar Crypto"
                    description="Você ouve falar em Crypto, mas não tem grana pra torrar nessa brincadeira. Use FLUs. Apreenda usar carteiras e todo mais."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="rocket_launch"
                    title="Quer ficar rico?"
                    description="Fala sério. Ninguém pode ficar rico com FLUs porque não valem nada! Se quiser ficar rico vá trabalhar, faz algum negócio, te vira amigo!"
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="card_giftcard"
                    title="O presente perfeito"
                    description="Dá FLUs para aquele amigo que não sabe usar Crypto, pra sua mãe que se acha moderninha, pro tio ou pra vizinha. Mostra como usar. Pre-sen-tão."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={expertPic}
              title="Apreenda o básico."
              description="Você não precisa saber muito para começar a juntar FLUs. Veja estes videos e começe hoje mesmo, em 15 minutos - meia hora se for muito burro!"
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "Comece aqui",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
