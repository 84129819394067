import { Component } from "react";
import MyToken from "contracts/MyToken.json";
import MyTokenSale from "contracts/MyTokenSale.json";
import KycContract from "contracts/KycContract.json";
import getWeb3 from "getWeb3";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
// import Counters from "pages/Presentation/sections/Counters";
try {
  window.ethereum.on("accountsChanged", (accounts) => {
    console.log(accounts);
    window.location.reload();
  });
} catch (e) { console.log("Metamask not connected.") }

class FluInfo extends Component {
  constructor() {
    super();
    this.state = { loaded: false, kycAddress: "0x123", tokenSaleAddress: "", userTokens: 0 };
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      this.web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      this.accounts = await this.web3.eth.getAccounts();

      // Get the contract instance.
      // this.networkId = await this.web3.eth.net.getId(); <<- this doesn't work with MetaMask anymore
      this.networkId = await this.web3.eth.getChainId();

      this.myToken = new this.web3.eth.Contract(
        MyToken.abi,
        MyToken.networks[this.networkId] && MyToken.networks[this.networkId].address
      );

      this.myTokenSale = new this.web3.eth.Contract(
        MyTokenSale.abi,
        MyTokenSale.networks[this.networkId] && MyTokenSale.networks[this.networkId].address
      );
      this.kycContract = new this.web3.eth.Contract(
        KycContract.abi,
        KycContract.networks[this.networkId] && KycContract.networks[this.networkId].address
      );

      const isCustomer = await this.kycContract.methods.kycCompleted(this.accounts[0]).call();
      const { _address: tokenSaleAddress } = this.myTokenSale;
      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      this.listenToTokenTransfer();
      this.getTokenRate();
      this.handleKycCheck();
      this.getTokenSaleBalance();
      this.setState({ loaded: true, tokenSaleAddress, isCustomer }, this.updateUserTokens);
    } catch (error) {
      // Catch any errors for any of the above operations.
      alert(`Failed to load web3, accounts, or contract. Check console for details.`);
      console.error(error);
    }
  };

  handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  handleKycSubmit = async () => {
    const { kycAddress } = this.state;
    console.log({ ky: kycAddress, acc0: this.accounts[0] });
    // await this.kycContract.methods.setKycCompleted
    await this.kycContract.methods.setKycCompleted(kycAddress).send({ from: this.accounts[0] });
    alert(`Account ${kycAddress} is now whitelisted`);
  };

  handleKycCheck = async () => {
    const response = await this.kycContract.methods.kycCompleted(this.accounts[0]).call();
    this.setState({ KycCheck: response ? "isKYC" : "" });
    // console.log(response, this.accounts[0]);
  };

  handleBuyToken = async () => {
    await this.myTokenSale.methods
      .buyTokens(this.accounts[0])
      .send({ from: this.accounts[0], value: 1000 });
  };

  handleBuyTokenOriginal = async () => {
    await this.myTokenSale.methods
      .buyTokens(this.accounts[0])
      .send({ from: this.accounts[0], value: 10 });
  };

  updateUserTokens = async () => {
    const userTokens = await this.myToken.methods.balanceOf(this.accounts[0]).call();
    this.setState({ userTokens });
    this.getTokenSaleBalance();
  };

  listenToTokenTransfer = async () => {
    await this.myToken.events.Transfer({ to: this.accounts[0] }).on("data", this.updateUserTokens);
  };

  getTokenRate = async () => {
    this.rate = await this.myTokenSale.methods.rate().call();
  };

  getTokenSaleBalance = async () => {
    // console.log(this.state.tokenSaleAddress);
    try {
      const { tokenSaleAddress } = this.state;
      this.tokenWallet = await this.myTokenSale.methods.wallet().call();
      const tokenSaleBalance = await this.myToken.methods.balanceOf(tokenSaleAddress).call();

      this.setState({ tokenSaleBalance });
    } catch (e) {
      this.setState({ tokenSaleBalance: "0" });
    }

    // console.log({saleWallet:this.tokenWallet});
    // this.tokenSaleBalance = await this.myToken.methods.balanceOf(this.state.tokenSaleAddress).call();
  };

  currency = (num) => {
    if (typeof num !== "number" && typeof num !== "string") {
      return num;
    }
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  };

  render() {
    const { tokenSaleBalance, userTokens, loaded, isCustomer, KycCheck } = this.state;

    if (!loaded || !tokenSaleBalance || !userTokens) {
      return <div>Loading Web3, accounts, and contract...</div>;
    }

    return (
      <MKBox component="section" py={3} className="FluInfo">
        <Container>
          <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
            <Grid item xs={12} md={4}>
              <DefaultCounterCard
                count={parseInt(userTokens || 0, 10)}
                suffix=" FLU"
                title="Você já tem FLUs!"
                description="Para comprar mais, clique no botão comprar."
              />
              <MKButton
                variant="gradient"
                color="info"
                size="large"
                fullWidth
                className={isCustomer}
                onClick={this.handleBuyToken}
              >
                Comprar mais 1000 FLUs
              </MKButton>
            </Grid>
            <Grid item xs={12} md={4} display="flex">
              <Divider
                orientation="vertical"
                sx={{ display: { xs: "none", md: "block" }, mx: 0 }}
              />
              <DefaultCounterCard
                count={parseInt(tokenSaleBalance, 10) / 1000000000}
                suffix=""
                title="Bilhões de FLU ainda disponíveis."
                description={`Na real, ${this.currency(tokenSaleBalance)} FLUs`}
              />
              <Divider
                orientation="vertical"
                sx={{ display: { xs: "none", md: "block" }, ml: 0 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DefaultCounterCard
                color="success"
                count={parseInt(this.rate, 10)}
                suffix=" wei/FLU"
                title="Um FLU vale 0.0000000000001 ETH."
                description="* Praticamente nada + os custos da transação."
                className={KycCheck}
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      /*
      <div className="App">
      <h1>This is the initial test for Free Lulas</h1>
      <h2 className={isCustomer ? "KYCok" : "KYCoff"}>
        {isCustomer ? "Your account is fine!" : "Please enable your account."}
      </h2>
      Address to allow:
      <input type="text" name="kycAddress" value={kycAddress} onChange={this.handleInputChange} />
      <button type="button" onClick={this.handleKycSubmit}>
        Add Address to Whitelist
      </button>
      <h2>Get your Free Lulas (FLU$ 1.00 = {this.rate} wei ETH)</h2>
      <p>Send Ether to this address: {tokenSaleAddress}</p>
      <p>You already have: FLU$ {userTokens}</p>
      <button type="button" onClick={this.handleBuyToken}>
        Buy more tokens
      </button>
      <button type="button" onClick={this.handleKycCheck} className={KycCheck}>
        Check KYC
      </button>
      <p>Available balance: FLU$ {tokenSaleBalance}</p>
      </div>
      */
    );
  }
}

export default FluInfo;
